.xen-footer{
    background-color: #f8f9fa;
    padding: 15px 10px !important;
    font-size: 1rem;
    position:fixed;
    width: 100%;
    bottom: 0;
    z-index: 10;
}
.xen-button{
    background-color: #fff;
    padding:10px 20px;
    border:none;
    margin:auto 10px;
}
.xen-report-button{
    color:#f2c574;
}
.xen-report-button:hover{
    background-color: #f2c574;
    color:#fff;
    border-color: #f2c574;
}
.xen-mark-button{
    color:#800080;
}
.xen-reponse-button{
    color:#cd5645;
}
.xen-mark-button:hover{
    background-color: #800080;
    color:#fff;
    border-color: #800080;
}
.xen-reponse-button:hover{
    background-color: #cd5645;
    color:#fff;
    border-color: #cd5645;
}
.xen-submit-button{
 float: right;
 background-color: #5363E0;
 color:#fff;
 margin-right:56px;
}
.xen-next-button{
    background-color: #5363E0;
    color:#fff;  
}
.xen-submit-button:hover,.xen-next-button:hover{
    color: #800080;
    background-color: #fff;
}
.xen-button_grid{
    display: grid;
    grid-template-columns: repeat(3, 2fr 2fr);
    grid-gap: 20px;
}
@media only screen and (max-width:1500px){
    .xen-button_grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        
    }
  }
  
@media only screen and (max-width:991px){
.xen-button_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}
}