.exam-question-image{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.exam-question-image-container {
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}