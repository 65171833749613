@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

body{
}

.App {
  /* min-height:100vh; */
  /* min-width:100vw; */
  font-family: "Poppins"
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dcm-link-cursor{
  cursor: pointer;
}

.fs-14{
  font-size:14px;
}

.fs-12{
  font-size:12px;
}

.fs-10{
  font-size:10px;
}

.fs-16{
  font-size:16px;
}

.fs-primary{
  color: #210062;
}

.form-check-input{
  border-color: black !important;
  border-width: 1px !important;
}