.dcm-options-main {
    /* height:calc(100vh - 245px); */
    background-color: #f8f9fa;
    padding: 10px;
    overflow-y: auto;
    /* min-height: 100%; */
    margin-right: 11px;
}

.dcm-body-row-main {
    min-height: 100%
}

.dcm-dicom-view-div {
    max-width: 200px;
    max-height: 200px;
}

.exam-statement {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
}

.dicom-half-view {
    height: 500px;
    /* overflow-y: auto; */
    margin-bottom: 10px;
}

.custom-range {
    appearance: none;
    /* Remove default styles */
    width: 100%;
    height: 8px;
    background-color: #333;
    /* Background color of the track */
    border-radius: 5px;
    outline: none;
    /* Remove focus outline */
    cursor: pointer;
    padding: 0;
}

/* Styling the filled portion of the range slider (before the thumb) */
.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #ffcc00, #ffcc00);
    /* Accent color */
    border-radius: 5px;
}

.custom-range::-moz-range-track {
    height: 8px;
    background: linear-gradient(90deg, #ffcc00, #ffcc00);
    /* Accent color */
    border-radius: 5px;
}

/* Styling the thumb (the draggable circle) */
.custom-range::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #ffcc00;
    /* Thumb color */
    border-radius: 50%;
    border: 2px solid #ffcc00;
    margin-top: -4px;
    /* Adjust thumb alignment */
    cursor: pointer;
}

.custom-range::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background-color: #ffcc00;
    /* Thumb color */
    border-radius: 50%;
    border: 2px solid #fff;
    cursor: pointer;
}